* {
  box-sizing: border-box;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 7));
  }
}

.service-slider {
  /* background: white; */
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;
}

.service-slider::before,
.service-slider::after {
  /* background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%); */
  content: "";
  height: 100px;
  position: absolute;
  width: fit-content;
  z-index: 2;
}

.service-slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.service-slider::before {
  left: 0;
  top: 0;
}

.service-slider .service-slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 14);
}
.service-slider .slide {
  height: 100px;
  width: fit-content;
  padding: 30px;
  margin-bottom: 1%;
}


/*  */
/*  */

.choosejob-job-content {
  width: 80%;
  margin: auto;
  height: 1240px;
  margin-top: 30px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}
.choosejobFrame {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: #181818;
  /* background: #181818 !important; */
}

.expertise-main-container {
  width: 220%;
  /* height: auto; */
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-left: -150px;
  /* width: fit-content;
block-size: fit-content; */
  /* border: 1px solid red; */
}
.highlighted {
  background-color: yellow;
}

.expertise-main-container-content {
  width: 90%;
  height: auto;
  /* margin: auto; */
}
.expertise-main-container-content-frontend-heading {
  margin-bottom: 10px;
  text-align: center;
  width: fit-content;
  margin-left: 1.5% !important;
}

.expertise-main-container-content-frontend-heading > span {
  width: 83px;
  height: 24px;
  font-size: 22px;
  line-height: 1.3em;
  font-weight: 600;
  line-height: 1.3em;
  font-family: "Poppins", sans-serif;
}
.service-container {
  text-align: left;
  margin: auto;
}

/* Apply the animation to an element */

@media screen and (max-width: 550px) {
  .slider .slide {
    height: 100px;
    width: 150px;
  }
  .expertise-main-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
  }

  .expertise-main-container-content {
    width: 100%;
    height: fit-content;
    margin: auto;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 380px) {
  .slider .slide {
    height: 100px;
    width: 100px;
  }

  .expertise-main-container {
    width: 100% !important;
  }
  .random {
    width: 100%;
    height: 250px;
    margin: auto;
    margin-top: 50px;
  }

  .expertise-main-container-content-heading > span {
    font-size: 40px;
  }
  .expertise-main-container-image {
    width: 80% !important;
  }
}

@media screen and (max-width: 820px) {
  .slider .slide {
    height: 70px;
    width: 80px;
  }

  .expertise-main-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
  }

  .expertise-main-container-content {
    width: 100%;
    height: fit-content;
    margin: auto;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 1024px) {
  .expertise-main-container {
    width: 100%;
    height: fit-content;
    /* background: #f8f5ed; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
  }

  .random {
    width: 368px;
    height: 368px;
  }
  .expertise-main-container-image {
    width: 368px;
    height: 368px;
    margin: auto;
    margin-top: 160px;
  }
  .expertise-main-container-content {
    width: 100%;
    height: fit-content;
    margin: auto;
    padding-bottom: 70px;
  }
}

.expertise-main-container {
  width: 220%;
  /* height: auto; */
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-left: -150px;
  /* width: fit-content;
block-size: fit-content; */
  /* border: 1px solid red; */
}

.expertise-main-container-content {
  width: 90%;
  height: auto;
  /* margin: auto; */
}

.expertise-main-container-content-frontend-heading {
  margin-bottom: 30px;
  text-align: left;
  margin-left: -21px;
  margin-left: 1.5% !important;
}

.expertise-main-container-content-frontend-heading > span {
  width: 83px;
  height: 24px;
  font-size: 22px;
  line-height: 1.3em;
  color: #fefefe;
  font-weight: 600;
  line-height: 1.3em;
  font-family: "Poppins", sans-serif;
}
@media screen and (max-width: 550px) {
  .expertise-main-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
  }

  .random {
    width: 368px;
    height: 368px;
  }
  .expertise-main-container-image {
    width: 368px;
    height: 368px;
    margin: auto;
    margin-top: 160px;
  }
  .expertise-main-container-content {
    width: 100%;
    height: fit-content;
    margin: auto;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 380px) {
  .expertise-main-container {
    width: 100% !important;
  }
  .random {
    width: 100%;
    height: 250px;
    margin: auto;
    margin-top: 50px;
  }

  .expertise-main-container-content-heading > span {
    font-size: 40px;
  }
  .expertise-main-container-image {
    width: 80% !important;
  }
}

@media screen and (max-width: 820px) {
  .expertise-main-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
  }

  .expertise-main-container-content {
    width: 100%;
    height: fit-content;
    margin: auto;
    padding-bottom: 70px;
  }
}
@media screen and (max-width: 1024px) {
  .expertise-main-container {
    width: 100%;
    height: fit-content;
    /* background: #f8f5ed; */
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
  }

  .random {
    width: 368px;
    height: 368px;
  }
  .expertise-main-container-image {
    width: 368px;
    height: 368px;
    margin: auto;
    margin-top: 160px;
  }
  .expertise-main-container-content {
    width: 100%;
    height: fit-content;
    margin: auto;
    padding-bottom: 70px;
  }
}

@media screen and (min-width: 338px) and (max-width: 990px) {
  .cs-tab_links.cs-style1 {
    margin-top: -40px;
    text-align: right;
  }
}

/* .slider {
 
  box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
  height: 100px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 960px;
}
.slider::before,
.slider::after {
 
  content: "";
  height: 100px;
  position: absolute;
  width: 200px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 30s linear infinite;
  display: flex;
  width: calc(250px * 14);
}

.slider .slide {
  height: 100px;
  width: 250px;
} */
